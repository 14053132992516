import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import throttle from "lodash/throttle";

import store from "./store/index.js";
import App from "./App";
import { saveState } from "./store/LocalStorage";

store.subscribe(
  throttle(() => {
    const defaultReducer = store.getState().defaultReducer;
    const authReducer = store.getState().authReducer;
    const miscReducer = store.getState().miscReducer;
    const permissionsReducer = store.getState().permissionsReducer;
    const modulesReducer = store.getState().modulesReducer;
    const endpointsReducer = store.getState().endpointsReducer;
    const subscriptionsReducer = store.getState().subscriptionsReducer;
    const sectionsReducer = store.getState().sectionsReducer;
    const authorizationsReducer = store.getState().authorizationsReducer;
    const adminEndpointsReducer = store.getState().adminEndpointsReducer;
    saveState({
      defaultReducer,
      authReducer,
      miscReducer,
      permissionsReducer,
      modulesReducer,
      endpointsReducer,
      subscriptionsReducer,
      sectionsReducer,
      authorizationsReducer,
      adminEndpointsReducer
    });
  }, 1000)
);

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
