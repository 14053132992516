import { Box } from "@chakra-ui/react";
import FilterForm from "../components/FilterForm";

const StylingAndContent = ({ showFilterForm = 1 }) => {
  return (
    <Box>
      {showFilterForm ? <FilterForm /> : null}
      Styling and Content component
    </Box>
  );
};

export default StylingAndContent;
