import { useSelector, useDispatch } from "react-redux";
import { Box, HStack, Button } from "@chakra-ui/react";

import BranchSelection from "./filterForm/BranchSelection";
import OrganizationSelection from "./filterForm/OrganizationSelection";
import { ACTION_BUTTONS } from "../store/constants";
import { updateDrawer } from "../store/actions/miscAction";
import { updateLocalModule } from "../store/actions/modulesActions";
import { updateLocalEndpoint } from "../store/actions/endpointsActions";
import { updateLocalSubscription } from "../store/actions/subscriptionsActions";
import { updateLocalSection } from "../store/actions/sectionsActions";
import ModuleSelection from "./subscriptions/subscriptionForm/ModuleSelection";
import { updateModuleId } from "../store/actions/defaultAction";

function FilterForm({ actionButton }) {
  const dispatch = useDispatch();
  const { organizationId, moduleId } = useSelector(
    (store) => store.defaultReducer
  );

  const btnText = {
    [ACTION_BUTTONS.CREATE_MODULE]: "Create Module",
    [ACTION_BUTTONS.CREATE_ENDPOINT]: "Create Endpoint",
    [ACTION_BUTTONS.CREATE_SUBSCRIPTION]: "Create Subscription",
    [ACTION_BUTTONS.CREATE_SECTION]: "Create Section",
    [ACTION_BUTTONS.ADD_AUTHORIZATION]: "Add Authorization",
    [ACTION_BUTTONS.ADD_ADMIN_ENDPOINT]: "Add Admin Endpoint",
  }[actionButton];

  const openDrawer = (actionButton) => {
    let drawer = null;
    switch (actionButton) {
      case ACTION_BUTTONS.CREATE_MODULE:
        dispatch(updateLocalModule({}));
        drawer = {
          title: "Create Module",
          isOpen: 1,
          content: { component: "ModuleForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;

      case ACTION_BUTTONS.CREATE_ENDPOINT:
        dispatch(updateLocalEndpoint({}));
        drawer = {
          title: "Create Endpoint",
          isOpen: 1,
          content: { component: "EndpointForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;

      case ACTION_BUTTONS.CREATE_SUBSCRIPTION:
        dispatch(updateLocalSubscription({}));
        drawer = {
          title: "Create Subscription",
          isOpen: 1,
          content: { component: "SubscriptionForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;

      case ACTION_BUTTONS.CREATE_SECTION:
        dispatch(updateLocalSection({}));
        drawer = {
          title: "Create Section",
          isOpen: 1,
          content: { component: "ModuleSectionForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;

      case ACTION_BUTTONS.ADD_AUTHORIZATION:
        drawer = {
          title: "Add Authorization",
          isOpen: 1,
          content: { component: "AuthorizationForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;

      case ACTION_BUTTONS.ADD_ADMIN_ENDPOINT:
        drawer = {
          title: "Add Admin Endpoint",
          isOpen: 1,
          content: { component: "AdminEndpointForm", id: null },
        };
        dispatch(updateDrawer(drawer));
        break;
    }
  };

  return (
    <Box
      overflow="visible"
      borderBottom="1px solid #ebebeb"
      m="0 -15px 30px"
      p="0 15px 15px"
    >
      <HStack overflow="visible" spacing="15px">
        <Box>
          <OrganizationSelection />
        </Box>
        <Box>
          <BranchSelection />
        </Box>
        <Box>
          {actionButton === ACTION_BUTTONS.CREATE_SECTION ? (
            <ModuleSelection
              organizationId={organizationId}
              onChange={(moduleId) => {
                dispatch(updateModuleId(moduleId));
              }}
              value={moduleId}
            />
          ) : null}
        </Box>
        <Box>
          <Button
            w="200px"
            h="56px"
            borderRadius="8px"
            background="#222"
            color="var(--white)"
            _hover={{ bg: "#000" }}
            onClick={() => {
              openDrawer(actionButton);
            }}
          >
            {btnText}
          </Button>
        </Box>
      </HStack>
    </Box>
  );
}

export default FilterForm;
