export const version = "1.8.22";

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

export const ACTION_BUTTONS = Object.freeze({
  CREATE_MODULE: 0,
  CREATE_ENDPOINT: 1,
  CREATE_SUBSCRIPTION: 2,
  CREATE_SECTION: 3,
  ADD_AUTHORIZATION: 4,
  ADD_GATEWAY: 5,
  ADD_ADMIN_ENDPOINT: 6,
});

export const MODULE_TYPES = Object.freeze({
  SIGN_IN: 1,
  OTHER: 0,
});

export const MODULE_ENDPOINTS_TYPES = Object.freeze({
  MODULE_ON_MOUNT: 0,
  INITIAL_LOAD: 1,
  SUBMIT: 2,
  REFRESH_ENDPOINTS: 3,
});

export const BODY_OPTIONS = Object.freeze({
  NONE: "none",
  RAW: "raw",
  FORM_DATA: "form-data",
  X_WWW_FORM_URLENCODED: "x-www-form-urlencoded",
});

export const navLinks = Object.freeze([
  {
    name: "main",
    links: [
      {
        name: "Main",
        links: [
          {
            name: "Modules",
            link: "/modules/",
          },
          {
            name: "Modules Subscriptions",
            link: "/modules-subscriptions",
          },
          {
            name: "Endpoint Configuration",
            link: "/endpoints",
          },
          {
            name: "Styling and Content",
            link: "/styling-and-content",
          },
          {
            name: "Module Sections",
            link: "/module-sections",
          },

          {
            name: "Authorization",
            link: "/authorization",
          },
          {
            name: "Admin Endpoints",
            link: "/admin-endpoints",
          },
        ],
      },
    ],
  },
]);

export const stepsDefault = Object.freeze([
  {
    type: "organization",
    title: "Select an organization",
    description:
      "As a SuperAdmin, you need to select an organization to get started.",
    status: 0,
  },
  {
    type: "reportRange",
    title: "Select Report Range",
    description:
      "On the Left Sidebar select a report range between, Monthly, Weekly, Daily and realtime.",
    status: 0,
  },
  {
    type: "reportType",
    title: "Select Report Type",
    description: "Select a report type from the left sidebar navigation.",
    status: 0,
  },
  {
    type: "branch",
    title: "Select a branch",
    description:
      "Select a branch or all branches from the search box to view reports for that branch(es).",
    status: 0,
  },
  {
    type: "dateRange",
    title: "Select a date range",
    description: "Select a date range to view reports for that period.",
    status: 0,
  },
]);

export const PRODUCT_ID = "jX3yzIQ0pJjSxF2TGWZC"; //SS product ID
export const PRODUCT_SERVICE_ID = "c13131ad-ed8f-4d93-a94d-60f43a152a9d"; //SS Dashboard product service id
